import React, { Component } from 'react'

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

export class MapContainer extends Component {
  render() {
    const { google } = this.props
    return (
      <div style={{ height: 550 }}>
        <Map
          google={google}
          zoom={14}
          initialCenter={{
            lat: 50.8121042,
            lng: 4.3540488999999525,
          }}
        >
          <Marker
            onClick={this.onMarkerClick}
            name={'ROYAL BRUSSELS LAWN TENNIS CLUB'}
            position={{ lat: 50.8042096, lng: 4.3732087 }}
            icon={{
              url:
                'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/favicon/favicon-96x96.png',
              anchor: new google.maps.Point(32, 32),
              scaledSize: new google.maps.Size(32, 32),
            }}
          />
          <Marker
            onClick={this.onMarkerClick}
            name={'TENNIS CLUB UCCLE CHURCHILL'}
            position={{ lat: 50.810508, lng: 4.35779 }}
            icon={{
              url:
                'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/favicon/favicon-96x96.png',
              anchor: new google.maps.Point(32, 32),
              scaledSize: new google.maps.Size(32, 32),
            }}
          />
          <Marker
            onClick={this.onMarkerClick}
            name={'TENNIS COUVERTS MONTJOIE'}
            position={{ lat: 50.80896809999999, lng: 4.356890900000053 }}
            icon={{
              url:
                'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/favicon/favicon-96x96.png',
              anchor: new google.maps.Point(32, 32),
              scaledSize: new google.maps.Size(32, 32),
            }}
          />
        </Map>
      </div>
    )
  }
}

const LoadingContainer = props => <div>Fancy loading container!</div>

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDbBsPrzFreCqJTaD3CM4_VUSQ8YAF73rA',
  LoadingContainer: LoadingContainer,
})(MapContainer)

/*
const Map = () => (
  <GoogleMap
    iconUrl="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/favicon/favicon-96x96.png"
    center={{ lat: 50.81050750000001, lng: 4.357790000000023 }}
    containerElement={<div className={css(styles.map)} />}
    loadingElement={<div />}
    mapElement={<div style={{ height: '400px' }} />}
  />
)
*/

/*
   <Marker
            onClick={this.onMarkerClick}
            name={'ROYAL LEOPOLD CLUB'}
            position={{ lat: 50.8080459, lng: 4.352025899999944 }}
            icon={{
              url:
                'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/favicon/favicon-96x96.png',
              anchor: new google.maps.Point(32, 32),
              scaledSize: new google.maps.Size(32, 32),
            }}
          />*/
