import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Location } from '@reach/router'
import clsx from 'clsx'
import chroma from 'chroma-js'
import { Events } from 'react-scroll'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { useSpring, animated, config, useTrail, useChain } from 'react-spring'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { MdSort, MdClose } from 'react-icons/md'

import MenuItem from './MenuItem'
import MenuLang from './MenuLang'
import useScrollPosition from '../../hooks/useScrollPosition'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'block',
    width: '100%',
    zIndex: 5,
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBotom: theme.spacing(1),
    },
  },
  rootSecondary: {
    position: 'relative',
    display: 'block',
  },
  rootOpen: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
  },
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      height: '100vh',
    },
  },
  logo: {
    display: 'block',
    height: 'auto',
    width: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '150px',
    },
  },
  button: {
    color: theme.palette.primary.main,
  },
  trail: {
    display: 'block',
    position: 'relative',
    zIndex: 7,
  },
  backgroundMobile: {
    display: 'block',
    height: '100vh',
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    backgroundColor: '#fff',
  },
  phoneLink: {
    color: 'inherit',
    textDecoration: 'none',
    fontWeight: 800,
  },
  phoneGrid: {
    width: 'auto',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}))

const DENSE_SCROLL_POSITON = -300

const Menu = ({ page, variant }) => {
  const theme = useTheme()
  const classes = useStyles()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDense, setIsDense] = useState(false)

  const isMobile = useMediaQuery('(max-width:600px)')
  const { t, i18n } = useTranslation()

  const links = [
    {
      name: 'accueil',
      label: t('menu:accueil'),
      url: '/#acceuil',
      children: [],
    },
    {
      name: 'notre-ecole',
      label: t('menu:notre-ecole'),
      url: '/#notre-ecole',
      children: [],
    },
    {
      name: 'cycles-stages',
      label: t('menu:cycles-stages'),
      url: '/#cycles-stages',
      children: [],
    },
    {
      name: 'padel',
      label: t('menu:padel'),
      url: '/#padel',
      children: [],
    },
    {
      name: 'news',
      label: t('menu:news'),
      url: '/#news',
      children: [],
    },
    {
      name: 'partners',
      label: t('menu:partners'),
      url: '/#partners',
      children: [],
    },
    {
      name: 'contact',
      label: t('menu:contact'),
      url: '/#contact',
      children: [],
    },
  ]

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  })

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false)
  })

  useEffect(() => {
    Events.scrollEvent.register('begin', function(to, element) {
      handleMenuClose()
    })
  }, [])
  const color1 = chroma('#fff')
    .alpha(1)
    .css()
  const color2 =
    variant === 'primary'
      ? chroma('#fff')
          .alpha(0)
          .css()
      : '#fff'

  const rootStyle = useSpring({
    background: isDense ? color1 : color2,
  })

  const backgroundMobileRef = useRef()
  const backgroundMobileStyle = useSpring({
    opacity: isMenuOpen ? 1 : 0,
    ref: backgroundMobileRef,
  })

  const trailRef = useRef()
  const trail = useTrail(links.length, {
    opacity: (isMobile && isMenuOpen) || !isMobile ? 1 : 0,
    x: (isMobile && isMenuOpen) || !isMobile ? 0 : 20,
    height: (isMobile && isMenuOpen) || !isMobile ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
    ref: trailRef,
  })

  useChain(
    isMenuOpen
      ? [backgroundMobileRef, trailRef]
      : [trailRef, backgroundMobileRef],
    isMenuOpen ? [0, 0.2] : [0, 0.2]
  )
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      logoImageMobile: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < DENSE_SCROLL_POSITON && !isDense) {
      setIsDense(true)
    } else if (currPos.y > DENSE_SCROLL_POSITON && isDense) {
      setIsDense(false)
    }
  })

  return (
    <Location>
      {({ location }) => (
        <animated.div
          style={rootStyle}
          className={clsx(
            classes.root,
            variant === 'secondary' && classes.rootSecondary
          )}
        >
          <animated.div
            style={backgroundMobileStyle}
            className={clsx(classes.backgroundMobile)}
          />
          <Container maxWidth="xl" className={classes.container}>
            <nav className={classes.navbar}>
              <Link to="/">
                <Img
                  fluid={
                    isMobile
                      ? data.logoImageMobile.childImageSharp.fluid
                      : data.logoImage.childImageSharp.fluid
                  }
                  className={classes.logo}
                />
              </Link>

              <div
                className={classes.links}
                style={{
                  pointerEvents: !isMobile || isMenuOpen ? 'auto' : 'none',
                }}
              >
                {trail.map(({ x, height, ...rest }, index) => (
                  <animated.div
                    key={links[index].name}
                    style={{
                      ...rest,
                      transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
                    }}
                    className={classes.trail}
                  >
                    <MenuItem
                      key={links[index].name}
                      link={links[index]}
                      location={location}
                      isMobile={isMobile}
                      onMenuClose={handleMenuClose}
                      isScrollLink={variant === 'primary'}
                    />
                  </animated.div>
                ))}
              </div>
              <MenuLang page={page} />
              <div />

              {isMobile && (
                <IconButton
                  aria-label="open-menu"
                  className={classes.button}
                  size="large"
                  onClick={handleMenuToggle}
                >
                  {isMenuOpen ? (
                    <MdClose fontSize="inherit" />
                  ) : (
                    <MdSort fontSize="inherit" />
                  )}
                </IconButton>
              )}
            </nav>

            <Grid container className={classes.phoneGrid}>
              <Grid item xs={12} sm={12}>
                <Typography display="block" color="primary" variant="caption">
                  {'Tél : '}
                  <a
                    href="tel://+32 476 01 23 99"
                    className={classes.phoneLink}
                  >
                    +32 476 01 23 99
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography display="block" color="primary" variant="caption">
                  {'Infos intempéries : '}
                  <a
                    href="tel://+32 474 04 04 69"
                    className={classes.phoneLink}
                  >
                    +32 474 04 04 69
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </animated.div>
      )}
    </Location>
  )
}

Menu.defaultProps = {
  variant: 'primary',
}

export default Menu
