import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

export const DEFAULT_LANG = 'fr'
export const LANGS = ['fr', 'en']
export const NAMESPACES = [
  'home',
  'menu',
  'footer',
  'projetPedagogique',
  "AttestationEtRembourdement",
  'equipe',
  'clubs',
  'stages',
  'cycles',
  'form',
]

const resources = {}

LANGS.forEach(lang => {
  resources[lang] = {}
  NAMESPACES.forEach(ns => {
    console.log('path : ' + '../../locales/' + lang + '/' + ns + '.json')
    resources[lang][ns] = require('../../locales/' + lang + '/' + ns + '.json')
  })
})

console.log('resources', resources)

const i18nContext = lang => {
  const context = i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      fallbackLng: DEFAULT_LANG,
      whitelist: LANGS,
      ns: NAMESPACES,
      lng: lang,
      defaultNS: 'home',
      debug: true,
      initImmediate: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
        wait: true,
      },
      resources: resources,
      parseMissingKeyHandler: () => '', // Display an empty string when missing/loading key
    })

  context.language = lang
  context.languages = LANGS

  console.log('i18next.services.resourceStore', context)
  return context
}

//

export default i18nContext
