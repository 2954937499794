import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

import Link from '../Link'

import useHover from '../../hooks/useHover'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  link: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: '6px',
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'color 0.5s ease, background-color 0.5s ease',
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
  },
  linkActive: {
    color: theme.palette.secondary.main,
  },

  icon: {
    fontSize: '18px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
}))

const FrFlag = ({ className }) => (
  <SvgIcon viewBox="0 0 512 512" className={className}>
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="#fff" d="M0 0h512.005v512H0z" />
      <path fill="#00267f" d="M0 0h170.667v512H0z" />
      <path fill="#f31830" d="M341.333 0H512v512H341.333z" />
    </g>
  </SvgIcon>
)

const EnFlag = ({ className }) => (
  <SvgIcon viewBox="0 0 512 512" className={className}>
    <defs>
      <clipPath id="a">
        <path fillOpacity=".67" d="M250 0h500v500H250z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="translate(-256) scale(1.024)">
      <g strokeWidth="1pt">
        <path fill="#006" d="M0 0h1000.02v500.01H0z" />
        <path
          d="M0 0v55.903l888.218 444.11h111.802V444.11L111.802.003H0zm1000.02 0v55.9L111.802 500.01H0v-55.9L888.218 0h111.802z"
          fill="#fff"
        />
        <path
          d="M416.675 0v500.01h166.67V0h-166.67zM0 166.67v166.67h1000.02V166.67H0z"
          fill="#fff"
        />
        <path
          d="M0 200.004v100.002h1000.02V200.004H0zM450.01 0v500.01h100V0h-100zM0 500.01l333.34-166.67h74.535L74.535 500.01H0zM0 0l333.34 166.67h-74.535L0 37.27V0zm592.145 166.67L925.485 0h74.535L666.68 166.67h-74.535zm407.875 333.34L666.68 333.34h74.535l258.805 129.403v37.267z"
          fill="#c00"
        />
      </g>
    </g>
  </SvgIcon>
)

const MenuLang = ({ page, isMobile }) => {
  const classes = useStyles()
  const [hoverRef, isHovered] = useHover()
  const { t, i18n } = useTranslation()

  const lang = i18n.language

  return (
    <div className={classes.root} ref={!isMobile ? hoverRef : undefined}>
      {lang !== 'fr' && (
        <Link page={page} lang="fr" className={classes.link}>
          <FrFlag className={classes.icon} /> Français
        </Link>
      )}
      {lang !== 'en' && (
        <Link page={page} lang="en" className={classes.link}>
          <EnFlag className={classes.icon} /> English
        </Link>
      )}
    </div>
  )
}

MenuLang.defaultProps = {}

export default MenuLang
