import React from 'react'
import clsx from 'clsx'

import { Link as ScrollLink } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import Link from '../Link'
import Section from '../Section'
import Map from '../home/Map'

import pagesInfos from '../../config/i18n/pagesInfos'

//import { Material } from "inno-components/build/Icons"
import FavoriteIcon from '@material-ui/icons/Favorite'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '3em',
  },
  title2: {
    fontSize: '1em',
    fontWeight: 600,
    letterSpacing: '1px',
    marginBottom: '1em',
    color: '#FFFFFF',
  },
  text2: {
    fontSize: '0.85em',
    fontWeight: 100,
    letterSpacing: '1px',
    color: '#FFFFFF',
    marginBottom: '0.5em',
  },
  titleAddress: {
    color: theme.palette.secondary.main,
  },
  padd: {
    padding: '2em',
  },
  item: {
    color: '#fff',
  },
  marginLeft: {
    marginLeft: '1.6em',
  },
  icon: {
    color: '#fff',
  },
  button: {
    border: `solid 2px ${theme.palette.primary.main}`,
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  scrollLink: {
    cursor: 'pointer',
  },
  signature: {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  iconSignature: {
    color: '#FC0D1B',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  waves1: {
    position: 'absolute !important',
    right: 0,
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 0,
    pointerEvents: 'none',
  },
  waves2: {
    position: 'absolute !important',
    right: 0,
    left: 0,
    top: 10,
    width: '100%',
    zIndex: 0,
    pointerEvents: 'none',
  },
  waves3: {
    position: 'absolute !important',
    right: 0,
    left: 0,
    top: 20,
    width: '100%',
    zIndex: 0,
    pointerEvents: 'none',
  },
  signature: {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  iconSignature: {
    color: '#FC0D1B',
  },
  textColorSignature:{
    color: '#fff',
  },
  iconSignatureA: {
    color: '#E33030'
  },
  
}))

const responsive = [
  { breakpoint: 768, settings: { slidesToShow: 1 } },
  { breakpoint: 1024, settings: { slidesToShow: 2 } },
]

const Footer = ({ title, subtitle, children, variant }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "home/bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <section
        style={{
          position: 'relative',
        }}
      >
        <Map />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={classes.waves3}
          viewBox="0 0 1440 320"
        >
          <path
            fill="#022B47"
            fillOpacity="1"
            d="M0,96L120,101.3C240,107,480,117,720,106.7C960,96,1200,64,1320,48L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={classes.waves2}
          viewBox="0 0 1440 320"
        >
          <path
            fill="#E67E21"
            fillOpacity="1"
            d="M0,96L120,101.3C240,107,480,117,720,106.7C960,96,1200,64,1320,48L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={classes.waves1}
          viewBox="0 0 1440 320"
        >
          <path
            fill="#E7F1FE"
            fillOpacity="1"
            d="M0,96L120,101.3C240,107,480,117,720,106.7C960,96,1200,64,1320,48L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
          />
        </svg>
      </section>

      <footer style={{ position: 'relative', zIndex: 0 }} id="contact">
        <Section bg="#01263f">
          <Grid container>
            <Grid item xs={12} sm={4}>
              <h3 className={clsx(classes.title, classes.titleAddress)}>
                Brussels Tennis School
              </h3>

              <ul className={clsx(classes.ul)}>
                <li className={clsx(classes.title2)}>
                Contacter un manager pour des renseignements ou une inscription
                </li>
                <li className={clsx(classes.text2)}><b>Royal Brussels Lawn Tennis Club :</b><br/> Bruno Bloquet 0478/26.52.03</li>
                <li className={clsx(classes.text2)}><b>Padel Tennis Club Montjoie :</b><br/> Anthony Ducobu 0470/40.20.67</li>
                <li className={clsx(classes.text2)}><b>Tennis Club Uccle Churchill :</b><br/> Yvan Denis 0476/71.19.41</li>
              </ul>
              <br/>

              <ul className={clsx(classes.ul)}>
                <li className={clsx(classes.item)}>
                  Chaussée de Waterloo, 890
                </li>
                <li className={clsx(classes.item)}>1000 - Bruxelles</li>
                <li className={clsx(classes.item)}>+32 476 01 23 99</li>
                <li className={clsx(classes.item)}>
                  <a
                    href="mailto:info@brusselstennisschool.be"
                    className={clsx(classes.link)}
                  >
                    info@brusselstennisschool.be
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={4}>
              <h3 className={clsx(classes.title, classes.titleAddress)}>
                {t('footer:title1')}
              </h3>
              <ul className={clsx(classes.ul)}>
                <li className={clsx(classes.item)}>{t('footer:ouverture')}</li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={4}>
              <h3 className={clsx(classes.title, classes.titleAddress)}>
                {t('footer:title2')}
              </h3>
              <ul className={clsx(classes.ul)}>
                <li className={clsx(classes.item)}>
                  <ScrollLink
                    className={clsx(classes.scrollLink)}
                    to="accueil"
                    smooth={true}
                    duration={500}
                  >
                    {t('footer:liens.accueil')}
                  </ScrollLink>
                </li>
                <li className={clsx(classes.item)}>
                  <ScrollLink
                    className={clsx(classes.scrollLink)}
                    to="notre-ecole"
                    smooth={true}
                    duration={500}
                  >
                    {t('footer:liens.notre-ecole')}
                  </ScrollLink>
                </li>
                <li className={clsx(classes.item)}>
                  <Link page={pagesInfos.ComponentProfesseursDeTennis}>
                    {t('footer:liens.professeur-tennis')}
                  </Link>
                </li>
                <li className={clsx(classes.item)}>
                  <Link page={pagesInfos.ComponentProjetPedagogique}>
                    {t('footer:liens.projet-pedagogique')}
                  </Link>
                </li>
                
                
                <li className={clsx(classes.item)}>
                  <Link
                    page={pagesInfos.ComponentCyclesEtStagesCycleAnnuel20242025}
                  >
                    {t('footer:liens.cycle-annuel')}
                  </Link>
                </li>
                <li className={clsx(classes.item)}>
                  <Link page={pagesInfos.ComponentCyclesEtStagesStages}>
                    {t('footer:liens.stages')}
                  </Link>
                </li>
                <li className={clsx(classes.item)}>
                  <ScrollLink
                    className={clsx(classes.scrollLink)}
                    to="news"
                    smooth={true}
                    duration={500}
                  >
                    {t('footer:liens.news')}
                  </ScrollLink>
                </li>
                <li className={clsx(classes.item)}>
                  <ScrollLink
                    className={clsx(classes.scrollLink)}
                    to="contact"
                    smooth={true}
                    duration={500}
                  >
                    {t('footer:liens.contact')}
                  </ScrollLink>
                </li>
                <br/>
                <li className={clsx(classes.item)}>
                  <Link page={pagesInfos.ComponentAttestationEtRembourdement}>
                  {t('footer:liens.attestation')}
                  </Link>
                </li>
                <li className={clsx(classes.item)}>
                  <Link page={pagesInfos.ComponentAttestationEtRembourdement}>
                  {t('footer:liens.remboursement')}
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ padding: "2em", textAlign: "center" }}>
                <a
                  href="http://www.innosmile.be/"
                  title="Innosmile - Concepteur site internet"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.signature}
                >
                  made with <FavoriteIcon className={classes.iconSignature} />{' '}
                  by innosmile
                </a>
              </div>
            </Grid>
          </Grid>
        </Section>
      </footer>
    </React.Fragment>
  )
}

Footer.defaultProps = {
  variant: 'secondary',
}

export default Footer
