import React, { Component } from 'react'
import { Element } from 'react-scroll'
import { withTranslation } from 'react-i18next'

import Layout from './Layout'
import Menu from '../Menu'
import Footer from '../Footer'

const PrimaryLayout = ({ children, page, language, isDisplayFromHome }) => {
  return (
    <Layout language={language} page={page}>
      <Menu variant={isDisplayFromHome ? 'primary' : 'secondary'} page={page} />

      {children}
      <Element name="contact">
        <Footer isDisplayFromHome={isDisplayFromHome} />
      </Element>
    </Layout>
  )
}

export default PrimaryLayout
