import { red } from '@material-ui/core/colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#022B47',
    },
    secondary: {
      main: '#E67E21',
    },
    background: {
      default: '#fff',
    },
  },

  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: "'Work Sans', sans-serif",
    body2: {
      lineHeight: 1.8,
    },
    h1: {
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 800,
      fontSize: '3.5rem',
      lineHeight: 1.4,
      letterSpacing: '0em',
    },
    h2: {
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 800,
      lineHeight: 1.2,
      letterSpacing: '0em',
    },
  },

  overrides: {
    MuiListItem: {
      dense: {
        paddingTop: 1,
        paddingBottom: 1,
      },
    },
  },
})

export default responsiveFontSizes(theme)
