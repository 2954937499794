import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from '../../styles/theme'
import i18n from '../../config/i18n/context'
import pagesInfos from '../../config/i18n/pagesInfos'

import { I18nextProvider, withTranslation } from 'react-i18next'

const Layout = ({ children, language, page }) => {
  console.log('pagesInfos', pagesInfos)
  console.log('page', page)

  const hrefLangs = []

  if (page) {
    for (var key in page.path) {
      // check if the property/key is defined in the object itself, not in parent
      if (page.path.hasOwnProperty(key) && key !== language) {
        hrefLangs.push(
          <link
            key={key}
            rel="alternate"
            hreflang={key}
            href={'https://www.brusselstennisschool.be' + page.path[key]}
          />
        )
      }
    }
  }

  return (
    <I18nextProvider i18n={i18n(language)}>
      <Helmet>
        <html lang={language} />
        {hrefLangs}
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.min.css"
        />

        <meta
          name="google-site-verification"
          content="PVXhku-buYk6Bn7RYwIAp-CN848tmt0eKDqb0jpQQH0"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </I18nextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
}

Layout.defaultProps = {}

export default Layout
